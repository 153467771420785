import React from "react";
import { LayoutBehance } from "../../components";

const UwodzenieIPodryw = ({ location: { pathname } }) => {
  return (
    <LayoutBehance
      seo={{
        title: "Uwodzenie I Podryw",
        href: pathname,
        lang: "pl"
      }}
    >
     <section className="uip-page">
        <div className="container uip-container">
            <img className="img-fluid img--1" src={require("../../assets/img/behance/uip_bg1.jpg")} alt="" />
            <video
                width="100%"
                height="100%"
                loop
                autoPlay
                playsInline
                muted
                data-setup="{}"
            >
              <source
              src={require("../../assets/video/uip_video_1.mp4")}
              type="video/mp4"
              />
            </video>
            <img className="img-fluid img--2" src={require("../../assets/img/behance/uip_bg2.jpg")} alt="" />
            <video
                width="100%"
                height="100%"
                loop
                autoPlay
                playsInline
                muted
                data-setup="{}"
            >
              <source
              src={require("../../assets/video/uip_video_2.mp4")}
              type="video/mp4"
              />
            </video>
            <img className="img-fluid img--3" src={require("../../assets/img/behance/uip_bg3.jpg")} alt="" />
        </div>
        </section>
    </LayoutBehance>
  );
};
export default UwodzenieIPodryw;
